import axios from "axios";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../redux2";
import PickDate from "./Components/PickDate";
import Loading from "../../components/Loading";
import OneWay from "../../components/Flights/OneWay";
import Heading2 from "../../components/Heading/Headings2";
import RoundTrip from "../../components/Flights/RoundTrip";
import MultiCity from "../../components/Flights/MultiCity";
import ReturnTrip from "../../components/Flights/ReturnFlight";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import { getMyCompany, reset } from "../../redux2/user/userSlice";
import { getCorporate } from "../../redux2/corporate/corporateSlice";
import { getMyEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import {
  FOREIGN_PASSENGER,
  GDS,
  NDC,
  NON_DOCUMENT_HOLDER,
  TRIP_TYPE_MULTI_CITY,
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_MEMBER,
} from "../../common/constants";
import ModifySearchModal from "../../components/Modals/ModifySearchModal";
import Locals from "../../utils/localFlights";
import { getServiceChargeInternational } from "../../utils/serviceChargePrice";
import TabFilters from "./TabFilter";
import { getNDCAirShopping } from "../../components/NDC/Services/AirShopping";
import { collectOffersByReference } from "../../components/NDC/helpers";
import FlightGrid from "./Components/FlightGrid";
import { getDiscountByDestination } from "../../redux2/discount/discountSlice";

const RecentFlights = () => {
  const [data, setData] = useState();
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [airPrice, setAirPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [isReturn, setIsReturn] = useState(false);
  const [departData, setDepartData] = useState([]);
  const [countMulti, setCountMulti] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [multiCityParams, setMultiCityData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [CustomSearchModal, setCustomSearchModal] = useState(false);
  const [ndcData, setNdcData] = useState(null);
  const [channelType, setChannelType] = useState(["NDC", "GDS"]);

  const [airlinesStates, setAirlinesStates] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [currencyStates, setCurrencyStates] = useState("USD");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {
    origin,
    destination,
    startDate,
    endDate,
    tripType,
    classType: cabinClass,
    adults,
    labors,
    children,
    infants,
    childrenAge,
    infantsAge: infantAge,
    passengerTypeState,
    id: _id,
    airline,
  } = Object.fromEntries(query.entries());

  const dispatch = useDispatch();

  const { company, isLoading } = useSelector((state) => state.user);
  const { corporate, isLoading: corpLoading } = useSelector(
    (state) => state.corporate
  );
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { discount, isLoading: discountLoading } = useSelector(
    (state) => state.discount
  );

  let queryParam = {};

  if (tripType === TRIP_TYPE_MULTI_CITY) {
    const state = location?.state;

    if (state) {
      multiCityParams.length < 1 && setMultiCityData(state?.flightSearch);
    }
  } else {
    queryParam = {
      Origin: origin || "",
      Destination: destination || "",
      startDate: startDate || "",
      endDate: endDate || "",
      tripType: tripType,
      cabinClass: cabinClass,
      adults: adults,
      children: children,
      infants: infants,
      labors: labors,
    };
  }

  useEffect(() => {
    if (isLoading) return;

    if (user?.role === USER_ROLE_ENTERPRISE) {
      dispatch(getMyEnterprise());
    } else if (
      user?.role === USER_ROLE_AGENT ||
      user?.role === USER_ROLE_MEMBER
    ) {
      dispatch(getMyCompany());
      dispatch(getDiscountByDestination(destination));
    }

    dispatch(reset());
  }, [dispatch, isLoading, user, destination]);

  useEffect(() => {
    if (company?.corporate || enterprise?.corporate) {
      dispatch(getCorporate(company?.corporate || enterprise?.corporate));
    } else {
      let id =
        user.role === USER_ROLE_EMPLOYEE ? user?.corporate : company?.corporate;
      dispatch(getCorporate(id));
    }
  }, [dispatch, company, user, enterprise]);

  let isLocal = Locals.includes(origin) && Locals.includes(destination);

  const isEligibleForNDC =
    !isLocal &&
    tripType !== TRIP_TYPE_MULTI_CITY &&
    labors < 1 &&
    passengerTypeState !== NON_DOCUMENT_HOLDER;
  //  &&
  // user?.role === USER_ROLE_SUPERAGENT;

  let searchData = {
    NotHoldCountryId: passengerTypeState === FOREIGN_PASSENGER && isLocal,
    ...(cabinClass !== "Any" && { CabinClass: cabinClass }),
    segments: [],
    passengers: [],
    ...(passengerTypeState === "Non-Document-Holder" && {
      CurrencyType: "USD",
    }),
  };

  if (tripType === TRIP_TYPE_MULTI_CITY) {
    multiCityParams.length > 0 &&
      multiCityParams?.forEach((item) => {
        searchData.segments.push({
          Origin: item.origin,
          Destination: item.destination,
          PreferredTime: item.departureDate,
        });
      });
  } else {
    searchData.segments.push({
      Origin: origin,
      Destination: destination,
      PreferredTime: startDate,
    });

    if (tripType !== TRIP_TYPE_ONE_WAY) {
      searchData.segments.push({
        Origin: destination,
        Destination: origin,
        PreferredTime: endDate,
      });
    }
  }

  if (+labors > 0) {
    searchData.passengers.push({
      Code: "LBR",
      Quantity: labors || "0",
    });
  } else
    searchData.passengers.push({
      Code: "ADT",
      Quantity: adults || "1",
    });

  let childrenAgeArray = children?.split(",");
  let infantAgeArray = infants?.split(",");

  childrenAgeArray
    ? childrenAgeArray?.forEach((age) => {
        if (children > 0) {
          searchData.passengers.push({
            Code: "CNN",
            Quantity: children || "1",
            Age: "10",
          });
        }
      })
    : searchData.passengers.push({
        Code: "CNN",
        Quantity: children || "0",
        Age: "10",
      });

  infantAgeArray
    ? infantAgeArray?.forEach((age) => {
        if (infants > 0) {
          searchData.passengers.push({
            Code: "INF",
            Quantity: infants || "1",
            Age: "1",
          });
        }
      })
    : searchData.passengers.push({
        Code: "INF",
        Quantity: infants || "0",
      });

  useEffect(() => {
    async function fetchData() {
      await searchFlight(searchData);
      if (isEligibleForNDC) {
        const response = await getNDCAirShopping(searchData, setLoading);
        setNdcData(response);
      }
    }
    fetchData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    destination,
    origin,
    startDate,
    endDate,
    tripType,
    cabinClass,
    adults,
    children,
    infants,
    childrenAge,
    infantAge,
    passengerTypeState,
  ]);

  function normalizeGDS(gds) {
    if (!gds?.LowFareSearchRsp) return [];
    if (Array.isArray(gds?.LowFareSearchRsp?.AirPricingSolution)) {
      return gds?.LowFareSearchRsp?.AirPricingSolution.map((solution) => ({
        id: solution._attributes.Key,
        price: parseFloat(
          solution._attributes.TotalPrice.replace(/[A-Za-z]/g, "")
        ),
        channel: GDS,
        currency: solution._attributes.TotalPrice.match(/[A-Za-z]+/)[0],
        airline: solution.AirPricingInfo._attributes?.PlatingCarrier,
        flightDetails: solution.AirPricingInfo.BookingInfo,
        data: solution,
      }));
    } else {
      return [
        {
          id: gds?.LowFareSearchRsp?.AirPricingSolution._attributes.Key,
          price: parseFloat(
            gds?.LowFareSearchRsp?.AirPricingSolution._attributes.TotalPrice.replace(
              /[A-Za-z]/g,
              ""
            )
          ),
          channel: GDS,
          currency:
            gds?.LowFareSearchRsp?.AirPricingSolution._attributes.TotalPrice.match(
              /[A-Za-z]+/
            )[0],
          airline:
            gds?.LowFareSearchRsp?.AirPricingSolution.AirPricingInfo._attributes
              ?.PlatingCarrier,
          flightDetails:
            gds?.LowFareSearchRsp?.AirPricingSolution.AirPricingInfo
              .BookingInfo,
          data: gds?.LowFareSearchRsp?.AirPricingSolution,
        },
      ];
    }
  }

  function normalizeNDC(ndc) {
    if (!ndc?.OffersGroup) return [];
    return ndc?.OffersGroup?.AirlineOffers?.[0]?.AirlineOffer?.map((offer) => ({
      id: offer.OfferID.value,
      OffersGroup: offer,
      channel: NDC,
      price: offer.TotalPrice.SimpleCurrencyPrice.value,
      currency: offer.TotalPrice.SimpleCurrencyPrice.Code,
      airline: offer.OfferID.Owner,
      flightDetails: offer.PricedOffer,
    }));
  }

  const [sortedFlights, setSortedFlights] = useState([]);

  useEffect(() => {
    // if (!data || !ndcData) return;
    const normalizedGDS = normalizeGDS(data);
    const normalizedNDC = normalizeNDC(ndcData);

    const mergedFlights = [...normalizedGDS, ...normalizedNDC].sort(
      (a, b) => a.price - b.price
    );

    setSortedFlights(mergedFlights);
    localStorage.setItem("pairedRefs", JSON.stringify([]));
  }, [data, ndcData]);

  const searchFlight = async (flightData) => {
    setLoading(true);
    try {
      const response = await axios.post(API_URL + "flight/search", flightData);
      const { data } = response;

      setSortedFlights([]);
      setData(data);
      setAirPrice(data?.LowFareSearchRsp?.AirPricingSolution);
      setFlights(data?.LowFareSearchRsp?.AirSegmentList?.AirSegment);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const NDCSearchFlight = async (flightData) => {
    setLoading(true);
    try {
      if (isEligibleForNDC) {
        const response = await getNDCAirShopping(flightData, setLoading);
        setSortedFlights([]);
        setNdcData(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // let airPrice = data?.LowFareSearchRsp?.AirPricingSolution || [];
  // let flights = data?.LowFareSearchRsp?.AirSegmentList?.AirSegment || [];

  useEffect(() => {
    if (!loading && airPrice) {
      if (!(airPrice instanceof Array)) {
        let airPriceArray = [airPrice];
        setAirPrice(airPriceArray);
        // airPrice.push(airPriceArray);
      }
      if (!(flights instanceof Array)) {
        let airFlight = [flights];
        setFlights(airFlight);
        // flights.push(airFlight);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (
      isLocal ||
      passengerTypeState !== "Non-Document-Holder" ||
      tripType === TRIP_TYPE_MULTI_CITY
    ) {
      setCurrencyStates("ETB");
    }
  }, [isLocal, passengerTypeState, tripType]);

  let isLocalMulti = multiCityParams?.every(
    (item) => Locals.includes(item.origin) && Locals.includes(item.destination)
  );

  if (!sortedFlights && !loading) {
    return (
      <>
        <div className="container mx-auto mb-8">
          <PickDate
            queryParam={queryParam}
            searchFlight={searchFlight}
            searchData={searchData}
            NDCSearchFlight={NDCSearchFlight}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCarrier={setCarrier}
          />
        </div>
        <div className="">
          <div className="flex justify-start items-start mx-5 mt-5">
            {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && !isLocal && (
              <TabFilters
                searchData={searchData}
                searchFlight={searchFlight}
                carrier={carrier}
                airlinesStates={airlinesStates}
                setCarrier={setCarrier}
                setAirlinesStates={setAirlinesStates}
                currencyStates={currencyStates}
                setCurrencyStates={setCurrencyStates}
                channelType={channelType}
                setChannelType={setChannelType}
              />
            )}
          </div>
          <div className="flex flex-col justify-center items-center ">
            <div className="text-2xl font-bold text-main">No Flights Found</div>
            {/* go back button */}
            <div className="mt-10">
              <ButtonPrimary
                href={`/?origin=${queryParam.Origin}&destination=${queryParam.Destination}&tripType=${queryParam.tripType}&cabinClass=${queryParam.cabinClass}&adults=${queryParam.adults}&labors=${queryParam.labors}&children=${queryParam.children}&infants=${queryParam.infants}&startDate=${queryParam.startDate}&endDate=${queryParam.endDate}`}
              >
                Explore more flights
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </>
    );
  }

  // filter airPrice if price, bookingClass  are same
  let newAirPrice = [];
  if (airPrice?.length > 0 && tripType === TRIP_TYPE_ROUND_TRIP) {
    const uniqueAirPrice = [];
    airPrice.forEach((item) => {
      const key =
        item?.Journey[0]?.AirSegmentRef?._attributes?.Key ||
        item?.Journey[0]?.AirSegmentRef?.[0]?._attributes?.Key;
      const key2 = item?.Journey[0]?.AirSegmentRef?.[1]?._attributes?.Key;
      if (
        key &&
        !uniqueAirPrice.some(
          (uniqueItem) =>
            (uniqueItem?.Journey[0]?.AirSegmentRef?._attributes?.Key === key ||
              (uniqueItem?.Journey[0]?.AirSegmentRef?.[0]?._attributes?.Key ===
                key &&
                uniqueItem?.Journey[0]?.AirSegmentRef?.[1]?._attributes?.Key ===
                  key2)) &&
            uniqueItem._attributes.TotalPrice === item._attributes.TotalPrice
        )
      ) {
        uniqueAirPrice.push(item);
      }
    });
    newAirPrice = uniqueAirPrice;
  }

  let temp = [];
  let multiTemp = [];

  if (airPrice && tripType === TRIP_TYPE_ROUND_TRIP && isReturn) {
    airPrice.forEach((item) => {
      if (totalPrice === item._attributes.TotalPrice) {
        temp.push(item);
      }
    });
  }
  if (airPrice && tripType === TRIP_TYPE_MULTI_CITY && countMulti > 0) {
    airPrice.forEach((item) => {
      if (selectedId === item._attributes.Key) {
        multiTemp.push(item);
      }
    });
  }

  const rateDifference =
    currencyStates === "USD" && company
      ? Math.max((corporate?.galileoRate / corporate?.bankRate).toFixed(3), 1)
      : 1;

  if (
    isLoading ||
    corpLoading ||
    corporate?.exchangeRate === undefined ||
    discountLoading
  ) {
    return <Loading />;
  }

  //TODO: add service charge for enterprise / corporate
  function getServiceCharge(airPricing) {
    let basePrice;
    // Initialize passengersPrice object
    let passengersPrice = {
      ADTPrice: 0,
      CNNPrice: 0,
    };

    if (airPricing instanceof Array) {
      passengersPrice = airPricing.map((item) => {
        const passengerTypes =
          item.PassengerType instanceof Array
            ? item.PassengerType
            : [item.PassengerType];

        passengerTypes.forEach((passenger) => {
          if (passenger._attributes.Code === "ADT") {
            passengersPrice.ADTPrice = item._attributes.TotalPrice;
          }
          if (passenger._attributes.Code === "CNN") {
            passengersPrice.CNNPrice = item._attributes.TotalPrice;
          }
        });

        return passengersPrice;
      });
    } else {
      passengersPrice = {
        ADTPrice: airPricing._attributes.TotalPrice,
        CNNPrice: 0,
      };
    }

    let merged = passengersPrice[0] || passengersPrice;

    let ADTTotal = +merged.ADTPrice.toString().slice(3);
    let CNNTotal = +merged.CNNPrice?.toString().slice(3) || 0;

    if (passengerTypeState === "Non-Document-Holder") {
      ADTTotal = (ADTTotal * corporate?.exchangeRate).toFixed(2);
      CNNTotal =
        CNNTotal > 0 ? (CNNTotal * corporate?.exchangeRate).toFixed(2) : 0;
    }

    if (discount?.companyIds?.includes(company?._id)) {
      basePrice = discount?.serviceCharge * (+adults + +children + +labors);
    } else {
      // Calculate basePrice
      if (+labors > 0 && company?.isLabor) {
        basePrice = company?.serviceChargeLabor * +labors;
      } else if (tripType === TRIP_TYPE_ONE_WAY && company?.isOneWay) {
        basePrice = company?.serviceChargeOneWay * (+adults + +children);
      } else {
        basePrice =
          getServiceChargeInternational(
            +ADTTotal,
            company?.serviceChargeInternational
          ) *
            (+adults || +labors) +
          (+CNNTotal > 0
            ? getServiceChargeInternational(
                +CNNTotal,
                company?.serviceChargeInternational
              ) * +children
            : 0);
      }
    }

    if (!company && !enterprise) return 0;
    if (tripType === TRIP_TYPE_MULTI_CITY ? isLocalMulti : isLocal)
      return (
        company?.serviceChargeLocal * (+adults + +children + +labors) ||
        enterprise?.serviceChargeLocal +
          enterprise?.company?.serviceChargeLocal *
            (+adults + +children + +labors)
      );
    else {
      // basePrice = basePrice?.toString().slice(3);

      if (cabinClass === "Economy" || cabinClass === "Any") {
        return currencyStates === "USD"
          ? basePrice ||
              enterprise?.serviceChargeInternational +
                enterprise?.company?.serviceChargeInternational *
                  (+adults + +children + +labors)
          : basePrice * corporate?.exchangeRate ||
              enterprise?.serviceChargeInternational +
                enterprise?.company?.serviceChargeInternational *
                  (+adults + +children + +labors);
      } else {
        return currencyStates === "USD"
          ? company?.serviceChargeInternationalBusiness *
              (+adults + +children + +labors) ||
              enterprise?.serviceChargeInternationalBusiness +
                enterprise?.company?.serviceChargeInternationalBusiness *
                  (+adults + +children + +labors)
          : company?.serviceChargeInternationalBusiness *
              corporate?.exchangeRate *
              (+adults + +children + +labors) ||
              enterprise?.serviceChargeInternationalBusiness +
                enterprise?.company?.serviceChargeInternationalBusiness *
                  corporate?.exchangeRate *
                  (+adults + +children + +labors);
      }
    }
  }

  function getNDCList(flight) {
    const groupedOffers2 = collectOffersByReference(
      flight,
      ndcData?.OffersGroup?.AirlineOffers?.[0].AirlineOffer
    );

    return groupedOffers2;
  }

  let traceId = data?.LowFareSearchRsp?._attributes?.TraceId;

  let queryURl = `/?origin=${queryParam.Origin}&destination=${
    queryParam.Destination
  }&tripType=${multiCityParams.tripType || tripType}&cabinClass=${
    queryParam.cabinClass
  }&adults=${multiCityParams.adults || adults}&labors=${
    multiCityParams.labors || labors
  }&children=${queryParam.children || children}&infants=${
    multiCityParams.infants || infants
  }&startDate=${queryParam.startDate || multiCityParams.startDate}&endDate=${
    queryParam.endDate
  }&traceId=${traceId}&isLocal=${isLocal}&passengerTypeState=${passengerTypeState}&_id=${_id}`;

  let flightDetail = data?.LowFareSearchRsp?.FlightDetailsList?.FlightDetails;
  let bookingRef = data?.SearchPassenger?._attributes || data?.SearchPassenger;

  const renderOneWayFlights = (flight) => {
    return (
      // airPrice?.length > 0 &&
      // airPrice?.map((item, index) => (
      <OneWay
        key={flight?.data?._attributes?.Key}
        flight={flight?.data}
        flights={flights}
        flightDetail={flightDetail}
        query={queryURl}
        bookingRef={bookingRef}
        // charge={getServiceCharge() || 0}
        charge={getServiceCharge(flight?.data?.AirPricingInfo)}
        passengerTypeState={passengerTypeState}
        currencyStates={currencyStates}
        rateDifference={rateDifference}
      />
      // ))
    );
  };

  const renderMultiCityFlights = () => {
    return multiTemp.length === 0
      ? airPrice.length > 1 &&
          airPrice?.map((item, index) => (
            <MultiCity
              key={item?._attributes?.Key}
              flight={item}
              flights={flights}
              flightDetail={flightDetail}
              setTotalPrice={setTotalPrice}
              setSelectedId={setSelectedId}
              countMulti={countMulti}
              setCountMulti={setCountMulti}
              setIsReturn={setIsReturn}
              setDepartData={setDepartData}
              charge={getServiceCharge(item?.AirPricingInfo) || 0}
              currencyStates={currencyStates}
              passengerTypeState={passengerTypeState}
            />
          ))
      : multiTemp?.map((item, index) => (
          <MultiCity
            key={item?._attributes?.Key}
            flight={item}
            flights={flights}
            countMulti={countMulti}
            setCountMulti={setCountMulti}
            flightDetail={flightDetail}
            setTotalPrice={setTotalPrice}
            setIsReturn={setIsReturn}
            setDepartData={setDepartData}
            charge={getServiceCharge(item?.AirPricingInfo) || 0}
            query={queryURl}
            bookingRef={bookingRef}
            classType={departData.classType}
            currencyStates={currencyStates}
            passengerTypeState={passengerTypeState}
          />
        ));
  };

  const renderRoundTripFlights = (flight) => {
    return (
      // airPrice?.length > 0 &&
      // airPrice?.map((item, index) => (
      <RoundTrip
        key={flight?.data?._attributes?.Key}
        flight={flight?.data}
        flights={flights}
        flightDetail={flightDetail}
        setTotalPrice={setTotalPrice}
        isReturn={isReturn}
        setIsReturn={setIsReturn}
        setDepartData={setDepartData}
        charge={getServiceCharge(flight?.data?.AirPricingInfo)}
        passengerTypeState={passengerTypeState}
        currencyStates={currencyStates}
        rateDifference={rateDifference}
      />
      // ))
    );
  };

  const renderReturnTripFlights = () => {
    return temp?.map((item) => (
      <ReturnTrip
        key={item?._attributes?.Key}
        flight={item}
        flights={flights}
        flightDetail={flightDetail}
        setTotalPrice={setTotalPrice}
        query={queryURl}
        departData={departData.segment}
        charge={getServiceCharge(item?.AirPricingInfo)}
        classType={departData.classType}
        bookingRef={bookingRef}
        currencyStates={currencyStates}
        rateDifference={rateDifference}
      />
    ));
  };

  return (
    <div
      className={`nc-SectionGridFilterCard mt-8 px-4 lg:px-0 lg:mt-16 pb-24 lg:pb-20 lg:mx-16`}
      data-nc-id="SectionGridFilterCard"
    >
      {tripType === TRIP_TYPE_MULTI_CITY ? (
        <Heading2
          heading={`Flights from ${multiCityParams[countMulti]?.origin} to ${multiCityParams[countMulti]?.destination}`}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {countMulti > 0 ? multiTemp?.length : airPrice?.length} Flights
              <span className="mx-2">·</span>
              Multi City
              {/* <span className="mx-2">·</span>2 Guests */}
              <span className="mx-2">·</span>{" "}
              {moment(multiCityParams[countMulti]?.departureDate).format("ll")}
              <span className="mx-2">·</span> {adults} Adults {children}{" "}
              Children {infants} Infants
              <span className="mx-2">·</span> {cabinClass}
              <span className="mx-2">·</span>{" "}
              {isLocal ? "Local" : "International"}
            </span>
          }
        />
      ) : (
        <Heading2
          heading={
            <div>
              Flights from{" "}
              <span className="text-main">
                {isReturn
                  ? `${destination} to ${origin}`
                  : `${origin} to ${destination}`}
              </span>
            </div>
          }
          subHeading={
            <div className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {tripType === TRIP_TYPE_ROUND_TRIP && isReturn
                ? temp?.length
                : airPrice?.length}{" "}
              Flights
              <span className="mx-2">·</span>
              {tripType === TRIP_TYPE_ONE_WAY ? "One Way" : "Round Trip"}
              {/* <span className="mx-2">·</span>2 Guests */}
              <span className="mx-2">·</span>{" "}
              {moment(new Date(selectedDate), "ddd, MMM D").format("ll") ??
                moment(new Date(startDate)).format("ll")}
              {tripType === TRIP_TYPE_ROUND_TRIP &&
                " - " + moment(new Date(endDate)).format("ll")}
              <span className="mx-2">·</span>
              {+labors < 1
                ? `${adults} Adults ${children}
              Children ${infants} Infants`
                : `${labors} Labor`}
              <span className="mx-2">·</span> {cabinClass}
              <span className="mx-2">·</span>{" "}
              {isLocal ? "Local" : "International"}
              {/* {+labors > 0 && 1 + "labor"} */}
            </div>
          }
        />
      )}

      {tripType !== TRIP_TYPE_MULTI_CITY && !isReturn && (
        <PickDate
          queryParam={queryParam}
          searchData={searchData}
          searchFlight={searchFlight}
          NDCSearchFlight={NDCSearchFlight}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCarrier={setCarrier}
        />
      )}

      <div className="flex justify-between mx-2 ">
        <h1 className="text-xl">
          {isReturn ? "Select Return Flight" : "Select Departure Flight"}
        </h1>
        {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && (
          <div className="hidden xl:block">
            <button
              onClick={() => setCustomSearchModal(!CustomSearchModal)}
              className="px-4 py-2 bg-neutral-300 text-neutral-700 rounded-md hover:bg-neutral-200 focus:outline-none focus:bg-neutral-200 "
            >
              Modify Search
            </button>
            <ModifySearchModal
              show={CustomSearchModal}
              onHide={() => setCustomSearchModal(false)}
              query={queryParam}
              setAirPrice={setAirPrice}
              // airPrice={airPrice}
              setSelectedDate={setSelectedDate}
              setCustomSearchModal={setCustomSearchModal}
              isLocal={isLocal}
            />
          </div>
        )}
        {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && (
          <div className="sm:hidden">
            <Link
              to={`/?origin=${queryParam.Origin}&destination=${queryParam.Destination}&tripType=${queryParam.tripType}&cabinClass=${queryParam.cabinClass}&adults=${queryParam.adults}&labors=${queryParam.labors}&children=${queryParam.children}&infants=${queryParam.infants}&startDate=${queryParam.startDate}&endDate=${queryParam.endDate}`}
            >
              <button className="px-4 py-2 bg-neutral-300 text-neutral-700 rounded-md hover:bg-neutral-200 focus:outline-none focus:bg-neutral-200 ">
                Modify Search
              </button>
            </Link>
          </div>
        )}
        {(isReturn || countMulti > 0) && (
          <button
            onClick={() => {
              setIsReturn(false);
              setCountMulti((prev) => prev - 1);
            }}
            className="btn btn-primary"
          >
            Go Back
          </button>
        )}
      </div>
      <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
        <div className="">
          {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && !isLocal && (
            <TabFilters
              searchData={searchData}
              searchFlight={searchFlight}
              NDCSearchFlight={NDCSearchFlight}
              carrier={carrier}
              airlinesStates={airlinesStates}
              setCarrier={setCarrier}
              setAirlinesStates={setAirlinesStates}
              currencyStates={currencyStates}
              setCurrencyStates={setCurrencyStates}
              channelType={channelType}
              setChannelType={setChannelType}
            />
          )}
        </div>
        {loading ? (
          <Loading search={true} />
        ) : (
          <FlightGrid
            sortedFlights={sortedFlights}
            isLocal={isLocal}
            isReturn={isReturn}
            channelType={channelType}
            tripType={tripType}
            getNDCList={getNDCList}
            renderOneWayFlights={renderOneWayFlights}
            renderRoundTripFlights={renderRoundTripFlights}
            renderMultiCityFlights={renderMultiCityFlights}
            renderReturnTripFlights={renderReturnTripFlights}
            queryURl={queryURl}
            company={company}
            corporate={corporate}
            ndcData={ndcData}
            setLoading={setLoading}
            getServiceCharge={getServiceCharge}
            discount={discount}
          />
          // <div className="grid grid-cols-1 gap-6 rounded-3xl">
          //   <Fragment>
          //     {sortedFlights.map((flight) => {
          //       const ndcList = flight.channel === "NDC" && getNDCList(flight);

          //       return flight.channel === "NDC" ? (
          //         ndcList &&
          //           !isLocal &&
          //           !isReturn &&
          //           channelType?.includes("NDC") && (
          //             <Shopping
          //               key={flight.id}
          //               flight={ndcList}
          //               dataSet={ndcData}
          //               setLoading={setLoading}
          //               query={queryURl}
          //               company={company}
          //               corporate={corporate}
          //               charge={getServiceCharge}
          //             />
          //           )
          //       ) : (
          //         <Fragment>
          //           {tripType === TRIP_TYPE_ONE_WAY &&
          //             renderOneWayFlights(flight)}
          //           {/* {tripType === TRIP_TYPE_ROUND_TRIP &&
          //             isReturn &&
          //             renderReturnTripFlights()} */}
          //           {tripType === TRIP_TYPE_ROUND_TRIP &&
          //             !isReturn &&
          //             renderRoundTripFlights(flight)}
          //         </Fragment>
          //       );
          //     })}
          //     {/* {!isLocal && !isReturn && channelType?.includes("NDC") && (
          //       <Shopping
          //         dataSet={ndcData}
          //         setLoading={setLoading}
          //         query={queryURl}
          //         company={company}
          //         corporate={corporate}
          //         charge={getServiceCharge}
          //       />
          //     )} */}
          //     {channelType?.includes("GDS") && (
          //       <>
          //         {/* {tripType === TRIP_TYPE_ONE_WAY && renderOneWayFlights()} */}
          //         {tripType === TRIP_TYPE_MULTI_CITY &&
          //           renderMultiCityFlights()}
          //         {tripType === TRIP_TYPE_ROUND_TRIP &&
          //           isReturn &&
          //           renderReturnTripFlights()}
          //         {/* {tripType === TRIP_TYPE_ROUND_TRIP &&
          //           !isReturn &&
          //           renderRoundTripFlights()} */}
          //       </>
          //     )}
          //   </Fragment>
          // </div>
        )}
      </div>
    </div>
  );
};

export default RecentFlights;
