import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "../../redux2";
import { toast } from "react-toastify";
import { resetBooking } from "../../redux2/booking/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import ReturnDetail from "./ReturnDetail";
import Loading from "../Loading";
import { convertToNumber } from "../../utils/convertToNumber";
import convertNumbThousand from "../../utils/convertNumbThousand";
import StopFlightDetails from "../FlightDetails/StopFlightDetails";
import MultiCityFlightDetails from "../FlightDetails/MultiFlightDetails";
import {
  TRIP_TYPE_MULTI_CITY,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";
import { currencyType } from "../../utils/flightInfo";

const FlightDetail2 = () => {
  const { user } = useSelector((state) => state.auth);
  // const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const traceId = query.get("traceId");
  const tripType = query.get("tripType");

  const {
    segmentData,
    returnData,
    charges,
    classType,
    queryURL,
    bookingRef,
    segmentConnection,
    currency,
    rateDifference,
  } = location.state;

  const isSuper = [USER_ROLE_SUPERAGENT, USER_ROLE_EMPLOYEE].includes(
    user?.role
  );

  useEffect(() => {
    if (!isSuper && charges <= 0) {
      toast.error("Network Error, Please try again");
      navigate(-1);
    }
  }, [charges, isSuper, navigate]);

  let travelers = [];
  if (bookingRef instanceof Array) {
    travelers = bookingRef.map((item) => item._attributes);
  } else {
    travelers = [bookingRef];
  }

  let field = {
    TraceId: traceId,
    segments: [],
    passengers: travelers,
    // CurrencyType: passengerTypeState === "Non-Document-Holder" && "USD",
    CurrencyType: currency,
  };

  // field.push({
  //   TraceId: traceId,
  //   segments: [],
  //   // CabinClass: "Business",
  //   passengers: [
  //     //
  //     bookingRef,
  //   ],
  // });

  if (segmentConnection instanceof Array) {
    let i = 0;
    segmentData.forEach((item, index) => {
      field.segments.push({
        ...item,
        Provider: "1G",
        ClassOfService:
          item?.classType || classType?.[index]?._attributes?.BookingCode,
        ...(index === +segmentConnection[index]?._attributes?.SegmentIndex && {
          SegmentIndex: segmentConnection[index]?._attributes.SegmentIndex,
        }),
      });
    });
    i++;

    if (returnData) {
      let len = segmentData.length;
      returnData.forEach((item, index) => {
        field.segments.push({
          ...item,
          Provider: "1G",
          ClassOfService: classType[index + len]._attributes.BookingCode,
          ...(index + len ===
            +segmentConnection[i]?._attributes?.SegmentIndex && {
            SegmentIndex: segmentConnection[i]?._attributes.SegmentIndex,
          }),
        });
      });
      i++;
    }
  } else {
    segmentData.forEach((item, index) => {
      field.segments.push({
        ...item,
        ClassOfService:
          item.classType || classType[index]._attributes.BookingCode,
        Provider: "1G",
        ...(index === +segmentConnection?._attributes?.SegmentIndex && {
          SegmentIndex: segmentConnection?._attributes.SegmentIndex,
        }),
      });
    });

    if (returnData) {
      let len = segmentData.length;
      returnData.forEach((item, index) => {
        field.segments.push({
          ...item,
          Provider: "1G",
          ClassOfService:
            item.classType ||
            classType[index + segmentData.length]._attributes.BookingCode,
          ...(index + len === +segmentConnection?._attributes?.SegmentIndex && {
            SegmentIndex: segmentConnection?._attributes.SegmentIndex,
          }),
        });
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(API_URL + "flight/pricing-2", field);
        const { data } = response;
        setError(data?.Fault?.faultstring?._text);
        setData(data?.AirPriceRsp);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      navigate(-1);
    }
  }, [error, navigate]);

  const flightDetails = data?.AirPriceResult?.AirPricingSolution;
  const flightInfo = data?.AirItinerary;
  const fareRule = data?.AirPriceResult?.FareRule;

  const findCategory = () => {
    let rule = fareRule?.[0]?.FareRuleLong || fareRule?.FareRuleLong;
    let category = rule?.find((item) => {
      return item._attributes.Category === "16";
    });
    return category;
  };

  const handleLink = () => {
    dispatch(resetBooking());
    navigate(`/customer-detail?${queryURL}`, {
      state: {
        AirPricing:
          flightDetails instanceof Array ? flightDetails[0] : flightDetails,
        charges,
        bookingRef: travelers,
        AirSegment:
          flightInfo?.AirSegment instanceof Array
            ? flightInfo?.AirSegment
            : flightInfo?.AirSegment?._attributes,
        FlightKey: flightInfo.AirSegment,
        queryURL,
        isRoundTrip: returnData ? true : false,
        rule: findCategory()?._text,
        currency,
        rateDifference,
      },
    });
  };

  function totalPrice(price) {
    const airPrice = parseFloat(price?.toString().slice(3));

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    result = parseFloat(result) + (charges || 0);
    return convertNumbThousand(result);
  }

  function basePrice(price) {
    const airPrice = parseFloat(price?.toString().slice(3));

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    return convertNumbThousand(result);
  }

  if (loading) {
    return <Loading />;
  }

  let departFlight = [];
  let returnFlight = [];

  if (flightInfo?.AirSegment instanceof Array) {
    returnFlight = flightInfo?.AirSegment.filter((item) => {
      return item?._attributes?.Group === "1";
    });
    departFlight = flightInfo?.AirSegment.filter((item) => {
      return item?._attributes?.Group === "0";
    });
  }

  return (
    <div className="container mt-4 md:mt-8">
      <div className="row">
        <div className="col-md-9 ">
          {tripType === TRIP_TYPE_MULTI_CITY ? (
            flightInfo?.AirSegment?.map((item, index) => (
              <div className="row">
                <MultiCityFlightDetails
                  // key={index}
                  index={index}
                  flightInfo={item}
                  flightDetails={
                    flightDetails?.AirPricingInfo?.[0] ||
                    flightDetails?.AirPricingInfo
                  }
                  fareRule={fareRule?.[index]?.FareRuleLong}
                  isRoundTrip={true}
                />
              </div>
            ))
          ) : // <div className="row">
          //   <StopFlightDetails
          //     // key={index}
          //     flightInfo={flightInfo?.AirSegment}
          //     flightDetails={flightDetails?.[0]?.AirPricingInfo}
          //     fareRule={fareRule?.[0]?.FareRuleLong}
          //     index={0}
          //     isRoundTrip={true}
          //   />
          // </div>
          returnFlight?.length > 0 ? (
            <div className="row">
              <StopFlightDetails
                // key={index}
                flightInfo={departFlight}
                flightDetails={
                  flightDetails?.[0]?.AirPricingInfo ||
                  flightDetails?.AirPricingInfo
                }
                fareRule={fareRule?.[0]?.FareRuleLong}
                index={0}
                isRoundTrip={true}
              />
              <StopFlightDetails
                // key={index}
                flightInfo={returnFlight}
                flightDetails={
                  flightDetails?.[0]?.AirPricingInfo ||
                  flightDetails?.AirPricingInfo
                }
                fareRule={fareRule[1]?.FareRuleLong}
                index={1}
                isRoundTrip={true}
              />
            </div>
          ) : flightInfo?.AirSegment instanceof Array ? (
            <div className="row">
              <StopFlightDetails
                // key={index}
                flightInfo={flightInfo?.AirSegment}
                flightDetails={
                  flightDetails?.[0]?.AirPricingInfo ||
                  flightDetails?.AirPricingInfo
                }
                fareRule={fareRule[1]?.FareRuleLong || fareRule?.FareRuleLong}
                index={1}
                isRoundTrip={false}
              />

              {/* ))} */}
            </div>
          ) : (
            <ReturnDetail
              // flightInfo={flightInfo}
              flightInfo={flightInfo?.AirSegment?._attributes}
              flightDetails={flightDetails?.AirPricingInfo}
              fareRule={fareRule?.FareRuleLong}
              index={false}
              classType={classType}
            />
          )}
        </div>
        <div className="col-md-3 h-96 ">
          <div className="card shadow-lg">
            <div className=" p-3 border-b border-x-gray-400 border-solid text-center">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="card-title">Flight Cost</h3>
                </div>
                <div className="col-md-6">
                  <h3 className="card-title">
                    {basePrice(
                      flightDetails?._attributes?.TotalPrice ??
                        flightDetails?.[0]?._attributes?.TotalPrice
                    )}{" "}
                    {flightDetails?._attributes?.TotalPrice?.substring(0, 3) ||
                      flightDetails?.[0]?._attributes?.TotalPrice?.substring(
                        0,
                        3
                      )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="flex text-black flex-row justify-around">
                <div className="">
                  <p>Base Fare </p>
                  <p>Taxes and Fees </p>
                  <p>Flight Price </p>
                  <p>Service Charge </p>
                </div>
                <div className="">
                  <p>
                    {basePrice(
                      flightDetails?._attributes?.ApproximateBasePrice ??
                        flightDetails?.[0]?._attributes?.ApproximateBasePrice
                    )}{" "}
                    {flightDetails?._attributes?.ApproximateBasePrice.substring(
                      0,
                      3
                    ) ??
                      flightDetails?.[0]?._attributes?.TotalPrice?.substring(
                        0,
                        3
                      )}
                  </p>
                  <p>
                    {convertToNumber(
                      flightDetails?._attributes?.Taxes ??
                        flightDetails?.[0]?._attributes?.Taxes
                    )}{" "}
                    {flightDetails?._attributes?.Taxes.substring(0, 3) ??
                      flightDetails?.[0]?._attributes?.TotalPrice?.substring(
                        0,
                        3
                      )}{" "}
                  </p>
                  {/* convertToNumber(<p>{flightDetails?._attributes?.OtherCharges)}</p> */}
                  <p>
                    {basePrice(
                      flightDetails?._attributes?.TotalPrice ??
                        flightDetails?.[0]?._attributes?.TotalPrice
                    )}{" "}
                    {flightDetails?._attributes?.TotalPrice.substring(0, 3) ??
                      flightDetails?.[0]?._attributes?.TotalPrice?.substring(
                        0,
                        3
                      )}
                  </p>
                  <p>
                    {charges} {currencyType(currency)}
                  </p>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex justify-end">
                <div className="text-xl font-bold text-black">
                  Total Price:{" "}
                  {totalPrice(
                    flightDetails?._attributes?.TotalPrice ??
                      flightDetails?.[0]?._attributes?.TotalPrice
                  )}{" "}
                  {/* {convertToNumber(flightDetails?._attributes?.TotalPrice)}{" "} */}
                  {flightDetails?._attributes?.TotalPrice?.substring(0, 3) ??
                    flightDetails?.[0]?._attributes?.TotalPrice?.substring(
                      0,
                      3
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 space-y-8 mt-3">
          <div className="d-flex justify-end">
            <button
              className="btn btn-primary"
              onClick={() => handleLink(flightInfo)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightDetail2;
